import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import { ReactComponent as HomeIcon } from '../../images/icon/home-icon.svg';

import { formatDate } from '../../data-service/backend-functions/helpers';
import { WebinarContext } from '../../data-service/webinars/webinar-context';

// Blog Content

function WebinarGrid() {
  const webinarContext = useContext(WebinarContext);
  const { webinars, pastWebinars, featuredWebinars } = webinarContext;

  return (
    <>
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: 'url(' + bnrImg1 + ')' }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>DigiVidBIO Webinars</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <HomeIcon /> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      DigiVidBIO Webinars
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1">
          {!!webinars?.length && (
            <div className="container">
              {/* 16 px padding */}
              <div
                style={{
                  paddingBottom: '16px',
                  paddingTop: '16px',
                }}
              >
                <h2>Upcoming Webinars</h2>
              </div>
              <div className="row">
                {featuredWebinars.map((webinarPost) =>
                  FeaturedWebinarGridItem(webinarPost)
                )}
              </div>
              <div
                style={{
                  paddingBottom: '16px',
                  paddingTop: '16px',
                }}
              >
                <h2>Past Webinars</h2>
              </div>
              <div className="row">
                {pastWebinars.map((webinarPost, index) =>
                  WebinarGridItem(index, webinarPost)
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default WebinarGrid;

function WebinarGridItem(index, webinarPost) {
  const blogLink = `/webinars/${webinarPost.slug}`;
  return (
    <div key={index} className="col-xl-4 col-md-6">
      <div className="blog-card mb-30">
        <div className="post-media">
          <iframe
            src={webinarPost.iFrameUrl}
            width="100%"
            title={webinarPost.title}
          ></iframe>
        </div>
        <div className="post-info">
          <ul className="post-meta">
            <li className="author"></li>
            <li className="date">
              <i className="far fa-calendar-alt"></i>
              {/* Format the timestamp like Oct 5, 2023 */}
              {formatDate(webinarPost.postDate)}
            </li>
          </ul>
          <h4 className="post-title">
            <Link to={blogLink}>{webinarPost.title}</Link>
          </h4>
          <Link to={blogLink} className="btn btn-outline-primary btn-sm">
            Watch Now <i className="btn-icon-bx fas fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}

function FeaturedWebinarGridItem(webinarPost) {
  return (
    <iframe
      src={webinarPost.iFrameUrl}
      width="100%"
      height="800px"
      style={{
        marginBottom: '40px',
      }}
      title={webinarPost.title}
    ></iframe>
  );
}
