import { createContext, useEffect, useState } from "react";
import { getWebinars } from "./functions";

const WebinarContext = createContext();

const WebinarContextProvider = ({ children }) => {
  const [webinars, setWebinars] = useState([]);
  const [selectedWebinar, setSelectedWebinar] = useState();
  const fetchwebinars = async () => {
    const webinarPosts = [];
    const posts = await getWebinars();
    if (posts.length === 0) {
      return;
    }
    posts.forEach((post) => {
      webinarPosts.push(post);
    });

    setWebinars(webinarPosts);
  };
  useEffect(() => {
    fetchwebinars();
  }, []);

  const fetchWebinarBySlug = async (slug) => {
    console.log('fetchWebinarBySlug' + slug);

    if (webinars.length === 0) {
      console.log('webinars.length > 0');
      await fetchwebinars();
    }
    const post = webinars.filter((webinar_post) => webinar_post.slug === slug);
    if (post === undefined || post.length === 0) {
      return;
    }

    setSelectedWebinar(post[0]);
  };

  const featuredWebinars = webinars.filter((webinar) => webinar.isFeatured);
  const pastWebinars = webinars.filter((webinar) => !webinar.isFeatured);

  return (
    <WebinarContext.Provider
      value={{
        webinars,
        featuredWebinars,
        pastWebinars,
        setWebinars,
        selectedWebinar,
        setSelectedWebinar,
        fetchWebinarBySlug,
      }}
    >
      {children}
    </WebinarContext.Provider>
  );
};

export { WebinarContext, WebinarContextProvider };
